<template>
  <li>
    <router-link
      :to="item.to"
      exact-active-class="is-active"
    >
      <b-icon
        :icon="item.icon"
        custom-size="default"
      />
      <span class="menu-item-label">
        {{ item.label }}
      </span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'SidebarItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
