<template>
  <ul class="menu-list">
    <sidebar-item
      v-for="(item, index) in list"
      :key="index"
      :item="item"
    />
  </ul>
</template>

<script>
import SidebarItem from "@/components/SidebarItem";

export default {
  name: 'SidebarList',

  components: {
    SidebarItem,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
