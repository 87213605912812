<template>
  <div id="app">
    <Navbar />
    <Sidebar />
    <transition 
      name="fade"
      mode="out-in"
      :duration="200"
    >
      <div class="is-relative">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Navbar from "@/components/Navbar";
import Sidebar from "@/components/Sidebar";
import Repository from "@/repositories/Repository";

export default {
  name: 'DashboardLayout',

  components: {
    Navbar,
    Sidebar,
  },

  computed: {
    ...mapGetters('config', ['sidebarExpanded']),
  },

  created() {
    Repository.get('auth').me().then(() => {
      this.fetchCategories();
      this.fetchGames();
      this.fetchCompanies();
    }, (err) => {
      this.$buefy.toast.open({
        message: err.message,
        type: 'is-warning',
        position: 'is-bottom-right',
      });
    });
  },

  mounted() {
    const classes = {
      'has-aside-left': true,
      'has-aside-mobile-transition': true,
      'has-navbar-fixed-top': true,
      'has-aside-expanded': true,
      'has-aside-mobile-expanded': this.sidebarExpanded,
    };

    Object.keys(classes).filter(key => classes[key]).forEach(item => {
      document.documentElement.classList.add(item);
    });
  },

  destroyed() {
    document.documentElement.removeAttribute('class');
  },

  methods: {
    ...mapActions('data', ['fetchCategories', 'fetchGames', 'fetchCompanies']),
  },
};
</script>
