<template>
  <nav class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="toggleSidebar"
      >
        <b-icon :icon="sidebarToggleIcon" />
      </a>
      <div class="navbar-item has-control no-left-space-touch">
        <b-autocomplete
          :data="data"
          placeholder="Search for Games..."
          size="is-medium"
          clearable
          @typing="handleTyping"
          @select="handleSelect"
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-content is-truncated">
                {{ props.option.title }}
              </div>
            </div>
          </template>
        </b-autocomplete>
      </div>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="toggleNavbar"
      >
        <b-icon 
          :icon="navbarToggleIcon" 
          custom-size="default"
        />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': active }"
    >
      <div class="navbar-end">
        <a
          class="navbar-item is-desktop-icon-only"
          title="Log out"
          @click.once="logOut"
        >
          <b-icon 
            icon="logout"
            custom-size="default" 
          />
          <span>Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: 'Navbar',
  
  data() {
    return {
      data: [],
      active: false,
    };
  },

  computed: {
    ...mapGetters('config', ['defaultApiLocale', 'sidebarExpanded']),
    ...mapGetters('data', ['findGamesByWildcard']),

    sidebarToggleIcon() {
      return this.sidebarExpanded ? 'backburger' : 'forwardburger';
    },

    navbarToggleIcon() {
      return this.active ? 'close' : 'dots-vertical';
    },
  },

  watch: {
    $route() {
      this.active = false;
    },
  },

  methods: {
    ...mapActions('config', ['toggleSidebar']),

    ...mapActions('auth', {
      logOutAction: 'logOut',
    }),

    toggleNavbar() {
      this.active = !this.active;
    },

    handleTyping: debounce(function (search) {
      if (search.length < 2) {
        this.data = [];
        return;
      }
      this.data = this.findGamesByWildcard(search);
    }, 300),

    handleSelect(item) {
      if (!item) {
        this.data = [];
        return;
      }
      this.$router.push({ 
        name: 'GamesEdit', 
        params: { game: item.distribution_id },
      }).then(() => {
        this.data = [];
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },

    logOut() {
      this.logOutAction().finally(() => {
        this.$router.push({ 
          name: 'Login',
        });
      });
    },
  },
};
</script>
