<template>
  <aside class="aside is-placed-left is-expanded">
    <div class="aside-brand">
      <b-image src="https://img.freeonlinegame.app/logo.png" />
    </div>
    <div class="menu is-menu-main">
      <template v-for="(item, index) in menu">
        <p
          v-if="typeof item === 'string'"
          :key="index"
          class="menu-label"
        >
          {{ item }}
        </p>
        <sidebar-list
          v-else
          :key="index"
          :list="item"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import SidebarList from "@/components/SidebarList";

export default {
  name: 'Sidebar',

  components: {
    SidebarList,
  },

  data() {
    return {
      menu: [
        [
          {
            to: '/dashboard',
            icon: 'desktop-mac',
            label: 'Dashboard',
          },
          {
            to: '/games',
            icon: 'google-controller',
            label: 'Games',
          },
          {
            to: '/categories',
            icon: 'sitemap',
            label: 'Categories',
          },
          {
            to: '/companies',
            icon: 'domain',
            label: 'Companies',
          },
        ],
      ],
    };
  },
};
</script>
